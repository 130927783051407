function openConfirmation(sweetAlertInstance, confirmationActionFn, message) {
    sweetAlertInstance.fire({
        text: message,
        position: 'center',
        width: '20em',
        showConfirmButton: true,
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonText: "Yes, Delete",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: 'warning',
    }).then((result) => {
        if (result.isConfirmed && typeof confirmationActionFn === 'function') confirmationActionFn();
    });
}

export default openConfirmation;
