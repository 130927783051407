<script setup>
const props = defineProps(['user']);

const verificationStatus = props.user?.verification_status ?? 'unverified';
</script>

<template>
    <div class="d-flex justify-content-between inter-coamana">
        <div class="verified text-success" v-if="verificationStatus === 'verified'">
            <i class="fa-solid fa-circle-check"></i>
        </div>
        <div class="unverified" v-if="verificationStatus === 'unverified'">
            <i class="fa-solid fa-circle-minus"></i>
        </div>
        <div class="suspended" v-if="verificationStatus === 'suspended'">
            <i class="fa-solid fa-circle-xmark"></i>
        </div>
        <div class="blacklisted" v-if="verificationStatus === 'blacklisted'">
            <i class="fa-solid fa-circle-xmark"></i>
        </div>
    </div>
</template>

<style scoped>
.inter-coamana {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.unverified {
    color: #EE8509
}

.suspended {
    color: #F0483E
}

.blacklisted {
    color: #F0483E
}
</style>
