<template>
    <div @keyup.enter="searchForProduct(null)">
        <div class="col-12 mb-4">
            <div class="mb-2">
                <input
                    type="text"
                    class="form-control me-2 text-center"
                    v-model="search"
                    placeholder="Search for products or seller phone number. Eg. Eggs or 0791..."
                    style="
                        border: 0.2px solid #1d242e;
                        padding: 0.5rem 1rem;
                        background: #e3ebf3;
                    "
                />
                <div id="search-box"></div>
            </div>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <button
                    type="button"
                    class="btn add-button-coamana success-text white-text"
                    @click="searchForProduct(null)"
                    @keydown.enter="searchForProduct(null)"
                    style="
                        border-radius: 0.25rem;
                        padding: 0.5rem 2rem;
                        background: #4b9d30;
                    "
                >
                    Search
                    <i class="fa fa-spin fa-spinner" v-if="loading"></i>
                </button>
            </div>
        </div>
        <div>
            <div class="ms-auto">
                <div
                    class="d-flex flex-column flex-md-row justify-content-between"
                >
                    <div class="col-8">
                        <form action="">
                            <div class="col-md-4">
                                <!--Label: Markets, Attributes:markets -->
                                <div class="form-group">
                                    <select
                                        style="
                                            border: 0.2px solid #1d242e;
                                            padding: 0.5rem 1rem;
                                            background: #e3ebf3;
                                        "
                                        class="form-control js-example-basic-single"
                                        id="markets"
                                        v-model="markets"
                                        aria-describedby="markets-help"
                                        @change="searchForProduct(null)"
                                    >
                                        <option value="" disabled>
                                            -- Select Market --
                                        </option>
                                        <option
                                            :value="market.id"
                                            :key="market.id"
                                            v-for="market in allusermarkets"
                                        >
                                            {{ market?.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        <a
                            href="/four/market/products/create"
                            id="add-product-and-services"
                            style="
                                text-decoration: none !important;
                                color: #ee8509;
                                border-radius: 0.25rem;
                                border: 0.4px solid #ee8509;
                                background: #fff;
                            "
                            type="button"
                            class="primary-button-coamana tw-mt-3 sm:tw-mt-0"
                            data-show-tooltip="Add your product, services and delivery service for offer"
                        >
                            <i class="fa fa-light fa-plus"></i>
                            List new product
                        </a>
                        <div class="tw-antialiased tw-mt-2 md:tw-mt-0 md:tw-hidden">
                            <p>
                                List the products, services and logistics you offer to buyers
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!getProductsLoading" class="row" style="padding: 0 0.7rem">
            <div v-if="productsDetails.products.length > 0">
                <div
                    class="d-flex flex-column flex-md-row products-services-card my-4"
                    v-for="result in productsDetails.products"
                    :key="result?.product?.id"
                    style="
                        border-radius: 0.25rem;
                        border: 1px solid rgba(29, 36, 46, 0.3);
                        background: #fff;
                        padding: 0;
                        /* max-height: 160px; */
                        overflow: hidden;
                    "
                >
                    <div class="col-2 d-flex align-items-center">
                        <!-- <img -->
                        <!--     src="https://images.unsplash.com/photo-1682686580186-b55d2a91053c?q=80&w=2275&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" -->
                        <!--     style="height: 100%; width: 100%; object-fit: cover" -->
                        <!-- /> -->

                        <img
                            style="height: 100%; width: 100%; object-fit: cover"
                            :src="result?.product_image_paths"
                        />
                    </div>

                    <div class="col">
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 0.5rem;
                                flex-wrap: wrap;
                                padding: 0.5rem 2.25rem 0.5rem 0.5rem;
                                border-bottom: 1px solid rgba(29, 36, 46, 0.3);
                            "
                        >
                            <div class="">
                                <div style="margin: 0; font-weight: 700">
                                    <div>
                                        {{ result?.product?.name }}
                                        <span style="font-weight: 400">
                                        {{ result?.measurement_unit?.name }}
                                        Listed by
                                    </span>

                                        <div class="tw-flex md:tw-flex-none">
                                            {{ result?.member?.name ?? "a member" }}
                                            <UserIcons :user="result?.member"></UserIcons>
                                        </div>
                                        <span class="text-muted">
                                        at {{ result?.created_at }}
                                   </span>
                                    </div>

                                </div>

                            </div>
                            <div style="font-weight: 600">
                                {{
                                    currency
                                }}{{ result?.amount?.toLocaleString() }}
                            </div>
                        </div>
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 0.5rem;
                                flex-wrap: wrap;
                                padding: 0.5rem 2.25rem 0.5rem 0.5rem;
                            "
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    gap: 0.1rem;
                                    flex-direction: column;
                                "
                            >
                                <div>
                                    <strong>Market</strong>:

                                    {{ result.market?.name }} | {{ result.market?.address ?? ' ' }},
                                    {{ result.market?.l_g_a?.name }} , {{ result.market?.geo_state?.name }}
                                </div>
                                <div v-if="result?.last_sold_at">
                                    <strong
                                    >Last Sold at
                                        {{ result.market?.name }}</strong
                                    >: N{{
                                        Number(
                                            result?.last_sold_at
                                        )?.toLocaleString()
                                    }}
                                    |
                                    {{ result.measurement_unit.name }}
                                    |
                                    {{ result.last_sold_at_date }}
                                </div>
                                <div v-else>
                                    <strong
                                    >Last Sold at
                                        {{ result.market?.name }}</strong
                                    >: Unavailable
                                </div>
                                <div>
                                    <strong
                                    >{{ result.market?.name }} Market
                                        Recommended Price</strong
                                    >: {{
                                        currency
                                    }}{{
                                        Number(
                                            result?.market_price
                                        )?.toLocaleString()
                                    }}
                                    |
                                    {{ result?.measurement_unit?.name }}
                                    |
                                    {{ result?.market_price_updated_at }}
                                </div>
                            </div>
                            <div class="success-text">
                                <template v-if="searchEnabled">
                                    <a
                                        v-if=" productsDetails.markets[0]?.pivot?.user_id !== result?.member_id?.toString()"
                                        style="
                                            margin: 0;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 0.1rem;
                                            cursor: pointer;
                                        "
                                        class="text-success"
                                        :href="`/four/make-payment?productstock=${result.id}`"
                                    >
                                        Buy
                                        <svg
                                            width="9"
                                            height="8"
                                            viewBox="0 0 9 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                                fill="#4B9D30"
                                            />
                                            <path
                                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                                fill="#4B9D30"
                                            />
                                        </svg>
                                    </a>
                                    <p
                                        v-else
                                        style="
                                            margin: 0;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 0.1rem;
                                            cursor: pointer;
                                        "
                                        @click="
                                            showModal(
                                                result?.id,
                                                result?.product,
                                                result?.amount,
                                                result?.market_price,
                                                productsDetails.marketLeader
                                                    ?.pivot?.user_id
                                            )
                                        "
                                    >
                                        Update price
                                        <svg
                                            width="9"
                                            height="8"
                                            viewBox="0 0 9 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                                fill="#4B9D30"
                                            />
                                            <path
                                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                                fill="#4B9D30"
                                            />
                                        </svg>
                                    </p>
                                </template>
                                <p
                                    v-else
                                    style="
                                        margin: 0;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 0.1rem;
                                        cursor: pointer;
                                    "
                                    @click="
                                        showModal(
                                            result?.id,
                                            result?.product,
                                            result?.amount,
                                            result?.market_price,
                                            productsDetails.marketLeader?.pivot
                                                ?.user_id
                                        )
                                    "
                                >
                                    Update price
                                    <svg
                                        width="9"
                                        height="8"
                                        viewBox="0 0 9 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                            fill="#4B9D30"
                                        />
                                        <path
                                            d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                            fill="#4B9D30"
                                        />
                                    </svg>
                                </p>
                                <DeleteButton
                                    v-if="productsDetails.markets[0]?.pivot
                                                ?.user_id ===
                                            result?.member_id?.toString()"
                                    delete-type="product"
                                    :delete-url="`/four/products/${result.id}`"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NoProduct v-else/>
            <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel">
                        Update
                        <strong>{{ product?.product?.name }}</strong> Price
                    </h5>
                    <button
                        type="button"
                        class="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
            </div>

            <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="makePayment"
                aria-labelledby="makePaymentLabel"
            >
                <div class="offcanvas-header">
                    <h5 id="makePaymentLabel">
                        Make payment for
                        <strong>{{ product?.product?.name }}</strong> Price
                    </h5>
                    <button
                        type="button"
                        class="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="offcanvas-body">
                    <form action="">
                        <div class="row g-3">
                            <div class="col-md-12">
                                <!--Label: Amount, Attributes:amount -->
                                <div class="form-group">
                                    <label for="amount">Amount</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="amount"
                                        v-model="amount"
                                        aria-describedby="amount-help"
                                        placeholder="Amount"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="quantity"
                                        v-model="quantity"
                                        aria-describedby="quantity-help"
                                        placeholder="Quantity"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div
            v-else
            style="
                font-weight: 600;
                margin: 2rem auto;
                width: 100%;
                text-align: center;
            "
        >
            Loading Products...
        </div>
    </div>
    <div
        v-if="openUpdate"
        style="
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: grid;
            place-items: center;
            padding: 1rem;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 50;
        "
    >
        <div
            class="card mb-3"
            style="
                position: relative;
                border-radius: 0.25rem;
                border: 0.2px solid #fff;
                box-shadow: none;
                max-width: 500px;
            "
        >
            <svg
                style="
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    cursor: pointer;
                "
                @click="this.toggleOpenUpdate()"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_8_6557)">
                    <path
                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#1D242E"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8_6557">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <div class="card-body" style="padding: 2rem 3rem">
                <h4 class="text-success">Update Your Product Price</h4>
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 0.8rem;
                    "
                >
                    <p style="color: #283342; margin: 0">
                        What Price is the product selling for today
                    </p>
                    <input
                        style="
                            padding: 0.4rem 0.6rem;
                            width: 100%;
                            border-radius: 0.25rem;
                            border: 0.2px solid #1d242e;
                            background: #fff;
                        "
                        type="number"
                        placeholder="5000"
                        :class="[errors.price ? 'is-invalid': '',!errors.price && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        v-model="newPrice"
                    />
                    <div class="invalid-feedback" v-if="errors.price">
                        {{ errors.price.toString() }}
                    </div>
                    <button
                        class="btn btn-success"
                        @click.prevent="
                            updatePrice(
                                Number(this.user_id) === Number(memberId)
                                    ? 'self'
                                    : 'market'
                            )
                        "
                    >
                        Update Price
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="blacklistedandassortedPopUp"
        tabindex="-1"
        role="dialog"
        aria-labelledby="blacklistedandassortedPopUp"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="sub-header-font">Proceed with Purchase</h4>
                    <button
                        type="button"
                        class="btn-close warn-text"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <template v-if="skip">
                        <template v-if="currentPageIs">
                            <div v-if="selectedUser?.blacklisted">
                                <div class="alert alert-danger" role="alert">
                                    <h4 class="sub-header-font">
                                        Suspended or blacklisted seller:
                                    </h4>
                                    <p>
                                        You cannot make a purchase from this
                                        seller. - This seller was
                                        blacklisted/suspended by x market.
                                    </p>
                                    <hr/>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    selectedUser?.member?.credit_score < 100
                                "
                            >
                                <div class="alert alert-warning" role="alert">
                                    <h4 class="sub-header-font">
                                        Unverified seller
                                    </h4>
                                    <p>
                                        This is an unverified seller, we
                                        recommend you pay at pick-up of goods or
                                        service. Note that you will be taking
                                        full responsibility for this purchase.
                                    </p>
                                    <hr/>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    selectedUser?.member?.credit_score >= 100
                                "
                            >
                                <div class="alert alert-warning" role="alert">
                                    <h4 class="sub-header-font">
                                        Verified seller
                                    </h4>
                                    <p>
                                        Based on our assessment, this seller can
                                        supplier good up to
                                        {{
                                            currency
                                        }}{{
                                            results.bnpl_balance.toLocaleString()
                                        }}
                                    </p>
                                    <hr/>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                            <div
                                class="alert alert-warning"
                                role="alert"
                                v-else
                            >
                                <h4 class="sub-header-font">
                                    Unverified seller
                                </h4>
                                <p>
                                    This is an unverified seller, we recommend
                                    you pay at pick-up of goods or service. Note
                                    that you will be taking full responsibility
                                    for this purchase.
                                </p>
                                <hr/>
                                <p class="mb-0"></p>
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                <div class="alert alert-success" role="alert">
                                    <strong
                                    >You are a market leader and you can
                                        purchase goods and services on behalf of
                                        your users.</strong
                                    >

                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <a href="">
                                                <div
                                                    class="card"
                                                    :style="
                                                        !formarketmember
                                                            ? {
                                                                  backgroundColor:
                                                                      '#00AB4D',
                                                                  color: 'white',
                                                              }
                                                            : ''
                                                    "
                                                >
                                                    <div
                                                        class="card-body"
                                                        @click.prevent="
                                                            formarketmember = false
                                                        "
                                                    >
                                                        For you
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6">
                                            <a
                                                href=""
                                                @click.prevent="
                                                    formarketmember = true
                                                "
                                            >
                                                <div
                                                    class="card"
                                                    :style="
                                                        formarketmember
                                                            ? {
                                                                  backgroundColor:
                                                                      '#00AB4D',
                                                                  color: 'white',
                                                              }
                                                            : ''
                                                    "
                                                >
                                                    <div class="card-body">
                                                        For Market Member
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6"></div>
                                    </div>
                                    <div class="row g-3" v-if="formarketmember">
                                        <form action="">
                                            <div class="col-md-12 mt-3">
                                                <!--Label: Member, Attributes:member -->
                                                <div class="form-floating">
                                                    <select
                                                        class="form-select"
                                                        id="member"
                                                        v-model="member"
                                                        aria-describedby="member-help"
                                                        @change="changeUser"
                                                        :class="[
                                                            errors.member
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.member &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    >
                                                        <option
                                                            :value="member.id"
                                                            v-for="member in props.members"
                                                            :key="member.id"
                                                        >
                                                            {{ member.name }}
                                                            -
                                                            <span
                                                                class="text-muted"
                                                            >{{
                                                                    member.phone
                                                                }}</span
                                                            >
                                                        </option>
                                                    </select>
                                                    <label for="member"
                                                    >Member</label
                                                    >

                                                    <div
                                                        class="invalid-feedback"
                                                        v-if="errors.member"
                                                    >
                                                        {{
                                                            errors.member.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="currentPageIs">
                            <div v-if="selectedUser?.blacklisted">
                                <div class="alert alert-danger" role="alert">
                                    <h4 class="sub-header-font">
                                        Suspended or blacklisted seller:
                                    </h4>
                                    <p>
                                        You cannot make a purchase from this
                                        seller. - This seller was
                                        blacklisted/suspended by x market.
                                    </p>
                                    <hr/>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    selectedUser?.member?.credit_score < 100
                                "
                            >
                                <div class="alert alert-warning" role="alert">
                                    <h4 class="sub-header-font">
                                        Unverified seller
                                    </h4>
                                    <p>
                                        This is an unverified seller, we
                                        recommend you pay at pick-up of goods or
                                        service. Note that you will be taking
                                        full responsibility for this purchase.
                                    </p>
                                    <hr/>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    selectedUser?.member?.credit_score >= 100
                                "
                            >
                                <div class="alert alert-warning" role="alert">
                                    <h4 class="sub-header-font">
                                        Verified seller
                                    </h4>
                                    <p>
                                        Based on our assessment, this seller can
                                        supplier good up to
                                        {{
                                            currency
                                        }}{{
                                            results.bnpl_balance.toLocaleString()
                                        }}
                                    </p>
                                    <hr/>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                            <div
                                class="alert alert-warning"
                                role="alert"
                                v-else
                            >
                                <h4 class="sub-header-font">
                                    Unverified seller
                                </h4>
                                <p>
                                    This is an unverified seller, we recommend
                                    you pay at pick-up of goods or service. Note
                                    that you will be taking full responsibility
                                    for this purchase.
                                </p>
                                <hr/>
                                <p class="mb-0"></p>
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                <div class="alert alert-success" role="alert">
                                    <strong
                                    >You are a market leader and you can
                                        purchase goods and services on behalf of
                                        your users.</strong
                                    >

                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <a href="">
                                                <div class="card">
                                                    <div
                                                        class="card-body text-black"
                                                        :class="{
                                                            'active-card-purchase text-white':
                                                                !formarketmember,
                                                        }"
                                                        @click.prevent="
                                                            formarketmember = false
                                                        "
                                                    >
                                                        For you
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6">
                                            <a
                                                href=""
                                                @click.prevent="
                                                    formarketmember = true
                                                "
                                            >
                                                <div class="card">
                                                    <div
                                                        class="card-body"
                                                        :class="{
                                                            'active-card-purchase text-white':
                                                                formarketmember,
                                                        }"
                                                    >
                                                        For Market Member
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6"></div>
                                    </div>
                                    <div class="row g-3" v-if="formarketmember">
                                        <form action="">
                                            <div class="col-md-12 mt-3">
                                                <!--Label: Member, Attributes:member -->
                                                <div class="form-floating">
                                                    <select
                                                        class="form-select"
                                                        id="member"
                                                        v-model="member"
                                                        aria-describedby="member-help"
                                                        @change="changeUser"
                                                        :class="[
                                                            errors.member
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.member &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    >
                                                        <option
                                                            :value="member.id"
                                                            v-for="member in props.members"
                                                            :key="member.id"
                                                        >
                                                            {{ member.name }}
                                                            -
                                                            <span
                                                                class="text-muted"
                                                            >{{
                                                                    member.phone
                                                                }}</span
                                                            >
                                                        </option>
                                                    </select>
                                                    <label for="member"
                                                    >Member</label
                                                    >

                                                    <div
                                                        class="invalid-feedback"
                                                        v-if="errors.member"
                                                    >
                                                        {{
                                                            errors.member.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <div>
                        <button
                            type="button"
                            class="btn primary-button-coamana text-white"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                    <div class="">
                        <div>
                            <template v-if="skip">
                                <template
                                    v-if="results?.blacklisted"
                                ></template>
                                <template v-else>
                                    <template v-if="currentPageIs">
                                        <button
                                            type="button"
                                            id="proceedButton"
                                            @click.prevent="gotonextstep"
                                            class="btn text-white add-button-coamana"
                                        >
                                            Proceed
                                            <i class="fa fa-step-forward"></i>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button
                                            type="button"
                                            id="proceedButton"
                                            @click.prevent="
                                                chooseProduct('skip')
                                            "
                                            class="btn text-white add-button-coamana"
                                        >
                                            Proceed
                                            <i class="fa fa-step-forward"></i>
                                        </button>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <template
                                    v-if="selectedUser?.blacklisted"
                                ></template>
                                <template v-else>
                                    <template v-if="currentPageIs">
                                        <button
                                            type="button"
                                            @click.prevent="gotonextstep"
                                            class="btn text-white add-button-coamana"
                                        >
                                            Proceed
                                            <i class="fa fa-step-forward"></i>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button
                                            type="button"
                                            :disabled="
                                                formarketmember && !member
                                            "
                                            id="proceedButtonForward"
                                            @click.prevent="
                                                chooseProduct(selectedUser)
                                            "
                                            class="btn text-white add-button-coamana"
                                        >
                                            Proceed
                                            <i
                                                class="fa fa-ban"
                                                v-if="
                                                    formarketmember && !member
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-step-forward"
                                                v-else
                                            ></i>
                                        </button>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import NoProduct from "../../v4/market/compliance/NoProduct.vue";
import UserIcons from "../../v4/Verification/UserIcons.vue";
import DeleteButton from "../../DeleteButton.vue";

export default {
    name: "markets-index",
    components: {UserIcons, NoProduct, DeleteButton},
    props: {
        user_id: {
            type: Number,
            default: "",
        },
        market_id: {
            type: [String, Number],
            default: "",
        },
        name: {
            type: String,
            default: "",
        },

        productstodisplay: {
            type: [Object, Array],
            default: [],
        },
        marketleader: {
            type: [Object, Array],
            default: [],
        },
        usermarkets: {
            type: [Object, Array],
            default: [],
        },
        is_member: {
            type: [Number, String],
            default: false,
        },
        currency: {
            type: String,
            default: import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN,
        },
    },
    data: function () {
        return {
            page: 1,
            markets: "",
            products: this.productstodisplay,
            product: [],
            searchEnabled: false,
            loading: false,
            initialLoad: true,
            showBuyOptions: false,
            itemToBeBought: "",
            errors: "",
            price: 100,
            allusermarkets: this.usermarkets,
            productgroupStockPriceId: null,
            quantity: [],
            openUpdate: false,
            updateLoading: false,
            getProductsLoading: true,
            productsDetails: null,
            newPrice: "",
            memberId: null,
            selectedUser: null,
            skip: null,
            currentPageIs: true,
            formarketmember: false,
            member: "",
            search: "",
            amount: null,
            // currency: import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN,
        };
    },
    methods: {
        async getProducts() {
            const queryString = window.location;
            const urlParams = new URLSearchParams(queryString.search);
            const searchForDeliveries = urlParams.get('delivery')

            let searchFor = "";

            if (queryString.pathname === "/four/market/products") {
                searchFor = "markets";
            } else {
                searchFor = "user";
            }

            this.getProductsLoading = true;
            if (searchForDeliveries) {
                axios
                    .get(`/apis/v4/products?filterbydeliveries=true`)
                    .then((res) => {
                        this.getProductsLoading = false;
                        this.productsDetails = res.data;
                    })
                    .catch((err) => {
                        this.getProductsLoading = false;
                        this.errors = err.response.data.errors;
                    });
                this.searchEnabled = true;
            } else {
                axios
                    .get(`/apis/v4/products?${searchFor}=true`)
                    .then((res) => {
                        this.getProductsLoading = false;
                        this.productsDetails = res.data;
                    })
                    .catch((err) => {
                        this.getProductsLoading = false;
                        this.errors = err.response.data.errors;
                    });
            }
        },
        gotonextstep() {
            this.currentPageIs = false;
        },
        chooseProduct(product) {
            var myModalEl = document.querySelector(
                "#blacklistedandassortedPopUp"
            );
            var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

            modal.hide();
            if (this.skip) {
                return;
            }
            this.selectedUser = product.member;
            emits("userSelected", product.member);
            emits("productChosen", product);
        },
        chooseProductForModal(product) {
            var myModalEl = document.querySelector(
                "#blacklistedandassortedPopUp"
            );
            var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

            modal.toggle();

            if (product === "skip") {
                this.selectedUser = this.search;
                this.skip = true;
                emits("productChosen", "skip");
                emits("userSelected", this.search);
                return;
            }
            this.selectedUser = product;
        },
        toggleOpenUpdate() {
            this.openUpdate = !this.openUpdate;
        },
        updatePrice(updatefor) {
            this.updateLoading = true;
            const queryString = window.location;
            let updateFor = "self";

            if (queryString.pathname === "/four/market/products") {
                updateFor = "markets";
            }

            axios
                .post("/apis/v4/price-update", {
                    updateFor: updateFor,
                    price: this.newPrice,
                    stockId: this.productgroupStockPriceId,
                })
                .then(() => {
                    window.location.reload();
                })
                .catch((err) => {
                    this.updateLoading = false;
                    this.errors = err.response.data.errors;
                });
        },
        showModal(id, products, amount, marketPrice, marketLeaderId) {
            this.productgroupStockPriceId = id;
            this.product = products;
            // check if the user is not the market leader
            this.price =
                Number(this.user_id) !== Number(marketLeaderId) &&
                this.is_member === 1
                    ? Number(amount)
                    : Number(marketPrice);
            this.newPrice =
                Number(this.user_id) !== Number(marketLeaderId) &&
                this.is_member === 1
                    ? Number(amount)
                    : Number(marketPrice);
            this.memberId = marketLeaderId;
            this.toggleOpenUpdate();
        },
        searchForProduct(loadmore) {
            this.markets = "";

            if (loadmore != null) {
                this.page++;
            }
            if (this.search === "" || this.search === undefined) {
                return;
            }
            this.loading = true;
            this.searchEnabled = true;
            this.initialLoad = false;
            this.suggestedProducts = false;
            axios
                .post(`/ajx/search_products?page=${this.page}`, {
                    search: this.search,
                    rHash: "kx2dot5cns",
                    market: this.markets,
                })
                .then((response) => {
                    if (
                        Object.keys(response.data.payload.products).length < 1
                    ) {
                        // show empty state
                    }

                    console.log(response.data.payload.products);
                    if (loadmore != null) {
                        this.loading = false;
                        this.productsDetails.products.push(
                            ...response.data.payload.products
                        );
                    } else {
                        this.productsDetails.products =
                            response.data.payload.products;
                        this.allusermarkets = response.data.markets;
                        let Self = this;
                        $(".js-example-basic-single").select2("destroy");
                        $(".js-example-basic-single")
                            .select2({
                                theme: "bootstrap-5",
                            })
                            .on("change", function (e) {
                                Self.markets = $(this).val();
                                Self.filterByMarket();
                            });
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        filterByMarket() {
            this.loading = true;
            this.searchEnabled = true;
            this.initialLoad = false;
            this.suggestedProducts = false;
            axios
                .post("/ajx/search_products", {
                    search: this.search,
                    rHash: "kx2dot5cns",
                    market: this.markets,
                })
                .then((response) => {
                    this.productsDetails.products =
                        response.data.payload.products;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        showBuyingOptions(item) {
            this.showBuyOptions = true;
            this.itemToBeBought = item;
        },
    },
    async mounted() {
        let Self = this;

        $(".js-example-basic-single").select2({
            theme: "bootstrap-5",
        });
        await this.getProducts();
    },
};
</script>
<style>
@import "select2/dist/css/select2.css";
</style>
