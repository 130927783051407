<script setup>
import Swal from "sweetalert2";
import openConfirmation from "../confirmation-reuse";

const props = defineProps({
    deleteUrl: String,
    deleteType: String,
    deleteFn: Function
});
const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary"
    },
});

function confirmationAction() {
    if (typeof props.deleteFn === 'function') props.deleteFn();
    else {
        axios.delete(props.deleteUrl)
            .finally(() => {
                location.reload();
            });
    }
}

function deleteItem() {
    const message = `Are you sure you want to delete this ${props.deleteType}?`;
    openConfirmation(swalWithBootstrapButtons, confirmationAction, message);
}
</script>

<template>
    <button
        type="button"
        class="btn btn-outline-danger btn-sm mt-5"
        @click.prevent="deleteItem"
    >
        Delete
    </button>
</template>

<style scoped>

</style>
